<template>
  <v-text-field
    ref="phoneNumberTextfield"
    v-model="value"
    :rules="validators"
    outlined
    validate-on-blur
    placeholder="Wpisz numer telefonu"
    label="Numer telefonu"
    v-mask="getFieldMask('phoneNumber')"
    :clearable="field.clearable"
    @input="setValue"
  />
</template>

<script>
import inputMixin from '../../../mixins/InputMixin.vue'
import { getFieldMask } from '../../../utils'
import get from 'lodash/get'

export default {
  mixins: [inputMixin],
  data: () => ({
    value: null
  }),
  mounted() {
    this.value = get(this.data, this.field.value, '')
    this.$nextTick().then(() => {
      this.$refs.phoneNumberTextfield.focus()
    })
  },
  methods: {
    setValue(value) {
      this.$emit('update', {
        address: {
          phoneNumber: value
        }
      })
    },
    getFieldMask
  }
}
</script>
